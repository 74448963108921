import { AlertError } from '../components/Common/Alerts';
import { COMMON_SUBSCRIPTION_PLANS } from '../components/SettingBilling/constant';
import {
  CUSTOM_FIELD_TYPES_VALIDATION_MESSAGE, STAGE_RESERVERED_KEYWORDS, TASK_STATUS_MAP,
} from '../constants';
import { isArray, isChangedObject } from './dataType.util';
import { differenceInDaysForTimestamps } from './date.util';
import {
  compareTaskBodyWithTemplateBody,
  getAppendedHTML,
  getClickableLink, isValidURL, triggerCloseModal,
} from './helper.util';

export const OpenLinkInNewTab = (url) => {
  if (url) {
    const a = document.createElement('a');
    a.target = '_blank';
    a.href = getClickableLink(`${url}`);
    a.rel = 'noopener noreferrer';
    a.click();
  }
};

export const insertValueInInputField = ({ data, elementId, field }) => {
  const el = document.getElementById(elementId);
  const idx = el.selectionStart;
  let resp = field;
  if (data) {
    resp = `${(data).slice(0, idx)}${resp}${(data).slice(idx)}`;
  }
  el.focus();
  return resp;
};

export const closeAllOpenModal = (buttonIds = []) => {
  triggerCloseModal();
  buttonIds.forEach((d) => triggerCloseModal(d));
};

export const extractEmailFormEventOwnerString = (owner = '') => {
  const regex = /<.*?>/g;
  return owner.match(regex)?.[0]?.replace('<', '').replace('>', '');
};
export const checkIfSameTemplateUsedAndReturnOverridfeEmailObjectForTaskScreen = ({
  from, emailObject, mailbox, templateDetailMap, unSubscribeContent, template: templateUsed,
}) => {
  let isChanged = false;
  const {
    subject, body, files, ...rest
  } = emailObject || {};
  if (templateUsed && templateUsed.id) {
    const templateObj = templateDetailMap[templateUsed?.id]?.data;
    isChanged = compareTaskBodyWithTemplateBody({
      template: {
        subject: templateObj?.subject || '',
        body: templateObj?.body || '',
        files: templateObj?.files || [],
      },
      mailbox,
      task: {
        subject, body, files: files || [], from,
      },
      unSubscribeContent,
    });
  }
  if (isChanged || !templateUsed?.id) {
    return {
      ...rest, files, body, subject,
    };
  }
  return { ...rest };
};

export const findTemplateAndPrepareEmailObjectIfBodyMissingForTaskScreen = ({
  emailObject, templateDetailMap, mailbox, unSubscribeContent, from, template,
}) => {
  let overideEmailObject = { ...emailObject };
  if (template?.id && (overideEmailObject.body === undefined || overideEmailObject.body === null)) {
    const templateObject = templateDetailMap[template.id]?.data;
    if (templateObject) {
      overideEmailObject = {
        ...overideEmailObject,
        body: templateObject.body + getAppendedHTML({
          unSubscribeContent, defaultEmailAlias: from, mailbox,
        }),
        subject: templateObject.subject,
        files: templateObject.files,
      };
    }
  }
  return overideEmailObject;
};

export const isWorkflowStepByDayInterval = (value) => `${value}`.toLowerCase().includes('Steps by day interval'.toLowerCase());

export const convertArrOfObjectToMap = (data = []) => {
  const map = {};
  data.forEach((d) => {
    const key = Object.keys(d)[0];
    map[key] = d[key];
  });
  return map;
};

export const getQueryObject = (query) => new URLSearchParams(query);

export const checkIfStageNameIsReservedKeyword = (name) => {
  const resp = STAGE_RESERVERED_KEYWORDS.includes(name.toLowerCase());
  if (resp) {
    AlertError({
      head: 'This name is reserved keyword',
    });
  }
  return resp;
};

export const isPluralQuantity = (count) => (count > 1 ? 's' : '');
export const generateCallDurationString = (callDuration) => {
  const hrs = Math.floor(callDuration / 3600);
  const mins = Math.floor(callDuration / 60);
  const secs = callDuration % 60;
  return `${hrs ? `${hrs < 10 ? 0 : ''}${hrs} : ` : ''}${mins < 10 ? 0 : ''}${mins} : ${secs < 10 ? 0 : ''}${secs}`;
};

export const combineProspectTags = ({ prospects }) => Array.from(new Set(
  prospects.map((d) => d.tags || []).reduce((prev, curr) => {
    (prev).push(...(curr));
    return prev;
  }, []),
));

export const getClippedString = (str = '', length = 24) => {
  let modStr = str;
  if (str.length > length) {
    modStr = `${str.slice(0, length - 3)}...`;
  }
  return modStr;
};

export const generateMapFromList = ({
  data, valueKey = 'id', labelKey = 'name', needFullValue = false,
}) => {
  const newMap = {};
  if (isArray(data)) {
    data.forEach((d) => {
      newMap[d[valueKey]] = needFullValue ? d : d[labelKey];
    });
  }
  return newMap;
};
export const checkIfCustomFieldsAreValid = ({
  customFields, customFieldMap,
}) => {
  const errorFields = {};
  let isValid = true;
  Object.keys(customFields).forEach((key) => {
    if (![null, undefined, ''].includes(customFields[key]) && customFieldMap[key]) {
      const { validation, fieldType } = customFieldMap[key];
      if (validation && !validation(customFields[key])) {
        errorFields[key] = CUSTOM_FIELD_TYPES_VALIDATION_MESSAGE[fieldType];
        isValid = false;
      }
    }
  });
  return { errorFields, isValid };
};

export const getCrmRedirectUrlForObject = ({
  entityType, integrationMap, entityId, syncedCRM,
}) => {
  let redirectUrl = '';
  if (!integrationMap[syncedCRM]) return redirectUrl;
  switch (syncedCRM) {
    case 'hubspot': {
      const { uiDomain, portalId } = integrationMap[syncedCRM];
      redirectUrl = `https://${uiDomain}/contacts/${portalId}/`;
      switch (entityType) {
        case 'prospect': {
          redirectUrl = `${redirectUrl}contact/${entityId}`;
          break;
        }
        case 'clientAccount': {
          redirectUrl = `${redirectUrl}company/${entityId}`;
          break;
        }
        default:
          break;
      }
      break;
    }
    case 'pipedrive': {
      const { uiDomain } = integrationMap[syncedCRM];
      redirectUrl = `https://${uiDomain}/deal/${entityId}`;
      switch (entityType) {
        case 'prospect': {
          redirectUrl = `https://${uiDomain}/person/${entityId}`;
          break;
        }
        case 'clientAccount': {
          redirectUrl = `https://${uiDomain}/organization/${entityId}`;
          break;
        }
        default:
          break;
      }
      break;
    }
    case 'salesforce': {
      const { uiDomain, platform } = integrationMap[syncedCRM];
      redirectUrl = `${uiDomain}`;
      switch (entityType) {
        case 'lead': {
          redirectUrl = `${uiDomain}/${platform === 'classic' ? entityId : `Lead/${entityId}/view`}`;
          break;
        }
        case 'contact': {
          redirectUrl = `${uiDomain}/${platform === 'classic' ? entityId : `Contact/${entityId}/view`}`;
          break;
        }
        case 'clientAccount': {
          redirectUrl = `${uiDomain}/${platform === 'classic' ? entityId : `Account/${entityId}/view`}`;
          break;
        }
        case 'opportunity': {
          redirectUrl = `${uiDomain}/${platform === 'classic' ? entityId : `Opportunity/${entityId}/view`}`;
          break;
        }
        default:
          break;
      }
      break;
    }
    case 'zoho': {
      const { uiDomain } = integrationMap[syncedCRM];
      switch (entityType) {
        case 'lead': {
          redirectUrl = `${uiDomain}/Leads/${entityId}`;
          break;
        }
        case 'contact': {
          redirectUrl = `${uiDomain}/Contacts/${entityId}`;
          break;
        }
        case 'clientAccount': {
          redirectUrl = `${uiDomain}/Accounts/${entityId}`;
          break;
        }
        case 'opportunity': {
          redirectUrl = `${uiDomain}/Deals/${entityId}`;
          break;
        }
        default:
          break;
      }
      break;
    }
    default: {
      break;
    }
  }
  return redirectUrl;
};

export const redirectToCRMProfile = ({
  entityType, integrationMap, entity, syncedCRM,
}) => {
  let entityId = '';
  let overridenEntityTpe = entityType;
  switch (syncedCRM) {
    case 'hubspot':
      entityId = entity.hubspotObjectId;
      break;
    case 'salesforce':
      entityId = entityType === 'prospect' ? entity.salesforceLeadId || entity.salesforceContactId : entity.salesforceObjectId;
      overridenEntityTpe = entityType === 'prospect' ? (entity.salesforceLeadId ? 'lead' : 'contact') : entityType;
      break;
    case 'pipedrive':
      entityId = entity.pipedriveObjectId;
      break;
    case 'zoho':
      entityId = entityType === 'prospect' ? entity.zohoLeadId || entity.zohoContactId : entity.zohoObjectId;
      overridenEntityTpe = entityType === 'prospect' ? (entity.zohoLeadId ? 'lead' : 'contact') : entityType;
      break;
    default:
      break;
  }
  const URL = getCrmRedirectUrlForObject({
    entityType: overridenEntityTpe, integrationMap, entityId, syncedCRM,
  });
  if (isValidURL(URL)) {
    window.open(URL, '_blank');
  } else {
    AlertError({
      head: 'CRM Redirection url is not valid',
    });
  }
};

export const isValidPassword = (password, email) => {
  const isValidLength = password.length > 7;
  const pattern = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[-+_!@#$%^&*.,?]).+$');
  return isValidLength && pattern.test(password) && email?.toLowerCase() !== password?.toLowerCase();
};

export const isBouncedEmail = ({ status, isBounced }) => status === TASK_STATUS_MAP.bounced || !!isBounced;

export const getWhatsAppRedirectUrl = ({ phone, text }) => `https://web.whatsapp.com/send/?phone=${phone}&text=${encodeURIComponent(text)}&type=phone_number&app_absent=0`;

export const getTimerString = (sec) => {
  const total = sec;
  const seconds = Math.floor((total) % 60);
  const minutes = Math.floor((total / 60) % 60);
  const hours = Math.floor((total / 60 / 60) % 24);
  return {
    total,
    hours,
    minutes,
    seconds,
    string: `${hours > 9 ? hours : `0${hours}`}:${
      minutes > 9 ? minutes : `0${minutes}`}:${
      seconds > 9 ? seconds : `0${seconds}`}`,
  };
};

export const getVoicemailUrl = (suffix) => Storage.get(suffix, { download: false });

export const getValueOrZero = (value) => (value || 0);

// export const getPlanIdFromItemPriceId = (itemPriceId) => {
//   switch (true) {
//     case itemPriceId.toLowerCase().includes('21days'):
//       return COMMON_SUBSCRIPTION_PLANS[0];
//     case itemPriceId.toLowerCase().includes('basic'):
//       return COMMON_SUBSCRIPTION_PLANS[1];
//     case itemPriceId.toLowerCase().includes('essential'):
//       return COMMON_SUBSCRIPTION_PLANS[2];
//     default:
//       return '';
//   }
// };

export const getPropertyValueOfNestedObject = ({ object, query }) => {
  if (query && object !== undefined) {
    const splitArray = query.split('.');
    return getPropertyValueOfNestedObject({ object: object?.[splitArray?.[0]], query: splitArray.slice(1, splitArray.length).join('.') });
  }
  return object;
};

export const voidFunction = () => {};

// TODO: Impose this restriction on expiry of plan instead of type of plan
// export const canAccessInFreeAccount = (accountSettings) => differenceInDaysForTimestamps(accountSettings?.subscription.nextBillingAt, Date.now()) > 0;

export const isFreeAccount = (accountSettings) => accountSettings?.subscription?.planId?.toLowerCase().includes(COMMON_SUBSCRIPTION_PLANS[0].toLowerCase());

export const isBasicPlan = (accountSettings) => accountSettings?.subscription?.planId?.toLowerCase().includes(COMMON_SUBSCRIPTION_PLANS[1].toLowerCase());

export const isEssentialPlan = (accountSettings) => accountSettings?.subscription?.planId?.toLowerCase().includes(COMMON_SUBSCRIPTION_PLANS[2].toLowerCase());

export const getPlanIdFromSettings = (accountSettings) => accountSettings?.subscription?.planId;

export const isCustomPlan = (planId) => !(COMMON_SUBSCRIPTION_PLANS.includes(planId)) || planId.includes('external-');

export const remainingDaysForExpiry = (accountSettings) => differenceInDaysForTimestamps(accountSettings?.subscription?.nextBillingAt, Date.now());

export const canAccessInFreeAccount = (accountSettings) => (isFreeAccount(accountSettings) ? (remainingDaysForExpiry(accountSettings) + 1) > 0 : true);

export const getModifiedProperties = ({ prevBasicFields, modifiedBasicFields }) => {
  const result = {};
  Object.keys(prevBasicFields).forEach((key) => {
    if (isChangedObject(prevBasicFields[key], modifiedBasicFields[key])) {
      result[key] = modifiedBasicFields[key];
    }
  });
  return result;
};

export const isSproutsAccount = (companyEmail) => {
  const {
    REACT_APP_SPROUTS_ACCOUNT_ID,
  } = process.env;
  const secondPartCompanyEmail = companyEmail.split('@')[1];
  const commaSepValues = REACT_APP_SPROUTS_ACCOUNT_ID.split(',');
  return commaSepValues.includes(secondPartCompanyEmail);
};

