import { format } from 'date-fns';
import capitalize from 'lodash.capitalize';
import camelCase from 'lodash.camelcase';
import {
  isArray, isFunction, isObject, isChangedObject, isString,
} from './dataType.util';
import {
  WORKFLOW_TYPES, PROSPECT_FIELD_MAPPER, SENDER_MAPPER, ACCOUNT_FIELD_MAPPER, DYNAMIC_FIELD_MAPPER,
  // MISSING_VARIABLE_COLOR,
  AVAIALABLE_VARIABLE_COLOR,
  CUSTOM_FIELD_PLACEHOLDER_PREFIX_MAP,
  WORKFLOW_PROSPECT_STATUS_MAP,
  WORKFLOW_PROSPECT_STATUS_COLOR_CODE,
  UNSUBSCRIBE_CONTENT_CLASSNAME,
  DEFAULT_PROSPECT_STAGES,
  SUBSCRIBE_TYPES,
  TASK_TYPES,
  MAX_PROSPECT_CAN_BE_ADDED_TO_WORKFLOW_AT_A_TIME,
  SIGNATURE_STARTING_REGEX,
  MAX_CHARACTERS_ALLOWED_FOR_LINKEDIN_MESSAGE,
  MAX_CHARACTERS_ALLOWED_FOR_LINKEDIN_CONNECTION_REQUEST,
  MAX_CHARACTERS_ALLOWED_FOR_LINKEDIN_INMAIL,
  TASK_TYPE_ENUM,
  CUSTOM_FIELD_TYPE_ENUM,
  NO_SIGNATURE,
  CURRENCY_FORMAT_MAP,
  MAX_CHARACTERS_ALLOWED_FOR_WHATSAPP_MESSAGE,
} from '../constants';
import { PLUGIN_ENUM } from '../components/Plugins/commonConfigurations';
import { days, differenceInDaysForTimestamps } from './date.util';
import { isValidDate } from './formValidation.util';
// import { showAlertModal } from './modal.util';

// TODO: Remove this later
const getPropertyValue = (searchKey, obj = {}) => {
  const keys = searchKey.split('.');
  if (keys.length - 1) {
    return getPropertyValue(keys.slice(1).join('.'), obj[keys[0]] || {});
  }
  return obj[keys?.[0]];
};

export const showItemList = (value) => (value.length > 1
  ? `${value.slice(0, value.length - 1).join(', ')} & ${value[value.length - 1]}`
  : value.join(','));

const average = (arr) => {
  let total = 0;
  for (let i = 0; i < arr.length; i += 1) {
    total += arr[i];
  }
  return total / arr.length;
};

const generateProfileTagName = (profileName = '') => {
  let initials = profileName.match(/\b\w/g) || [];
  initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
  return initials;
};

export const areTagsPresent = ({ tags = [], filterTags = [] }) => {
  const tagLength = isArray(tags) ? tags.length : 0;
  const tagSet = Array.from(new Set([...filterTags, ...tags]));
  return tagLength >= tagSet.length;
};

/**
 * @function cleanObject - Function to clear object with null, undefined and '' values
 * @example
 * // returns { userName: 'example' }
 * cleanObject({ userName: 'example', email: null})
 * @returns {Object} Returns cleaned object
 */
const cleanObject = (obj) => {
  if (typeof obj !== 'object' || Array.isArray(obj)
    || obj === null || obj === undefined) return obj;
  const newObj = { ...obj };
  Object.keys(newObj).forEach((propName) => {
    if (newObj[propName] === null || newObj[propName] === undefined || newObj[propName] === '') {
      delete newObj[propName];
    }
  });
  return newObj;
};

const constructName = (obj) => Object.values(cleanObject(obj)).join(' ');

const validateForm = (requiredFields, fields, checkLength = 1) => !requiredFields.find((field) => Object.prototype.hasOwnProperty.call(fields, field)
  && fields[field].length < checkLength);

const makeOptions = (val) => ({ value: val, label: val });
const makeAllOptions = (arr) => (arr || []).map((d) => makeOptions(d));

const validateFormWithNestedObj = (requiredFields, fields, checkLen) => {
  const resArr = [];

  Object.keys(fields).forEach((f) => {
    const res = Object.keys(fields[f]).filter((ft) => requiredFields.includes(ft)).find((e) => (Object.prototype.hasOwnProperty.call(fields[f], e) && fields[f][e].length < checkLen));
    if (res) { resArr.push(res); }
  });

  return resArr.length === 0;
};

const ifAnyError = (errorState) => Object.values(errorState).every((e) => !e);

const allUsersObject = (allUsers) => {
  const obj = {};
  allUsers.forEach((user) => {
    obj[user.id] = user;
  });
  return obj;
};

const generateName = (data) => {
  if (!isObject(data)) return '';
  const { firstName = '', lastName = '', middleName = '' } = data;
  return ([firstName, lastName, middleName].filter((d) => d).map((d) => capitalize(d)).join(' ')).trim();
};

const generateNameWithoutCapitalizing = (data) => {
  if (!isObject(data)) return '';
  const { firstName = '', lastName = '', middleName = '' } = data;
  return ([firstName, lastName, middleName].filter((d) => d).join(' ')).trim();
};

const makeOwnerName = (id, allUsers) => {
  const AllUsersObj = allUsers.find((datum) => datum.id === id);
  let newName = '';
  if (AllUsersObj) {
    newName = generateName(AllUsersObj.basicInfo);
  }
  return newName;
};

const getTotalDays = (items) => {
  if (!items.length) return 0;
  let count = 0;
  items.forEach((d) => {
    const { days: weekDays = 0, hours = 0, minutes = 0 } = d;
    count += (((Number(weekDays) * 24 * 60) + (Number(hours) * 60) + (Number(minutes))) / 1440);
  });
  return (count % 1 ? Math.ceil(count) : count + 1);
};

const getTotalDaysForExactTime = (items) => {
  let count = 0;
  if (isArray(items) && items.length > 1) {
    count = differenceInDaysForTimestamps(new Date(items[items.length - 1].executionTime),
      new Date(items[0].executionTime));
  }
  return count;
};

const generateMetaInfoForWorkflow = (workflow) => {
  let metaInfo = {};
  if (workflow && isObject(workflow)) {
    const {
      steps, workflowSetting, id, isActive, isValid, workflowActiveFor = [], status = 'active',
      sharedWithOrganisation, workflowType, name, userId, stepCount,
    } = workflow;
    const len = isArray(steps) ? steps.length : 0;
    const findAuto = len
      ? Number((steps.filter((d) => d.task.type === 'auto').length / len) * 100).toFixed(0)
      : 0;
    const totalDays = len ? workflowType === WORKFLOW_TYPES[0]
      ? getTotalDays(steps)
      : getTotalDaysForExactTime(steps)
      : 0;
    metaInfo = {
      id,
      isActive,
      isValid,
      name,
      workflowType,
      steps: len,
      days: totalDays,
      automated: `${findAuto}%`,
      owner: userId,
      userId,
      workflowSharedType: sharedWithOrganisation,
      workflowActiveFor,
      status,
      workflowSetting,
      workflowSteps: steps || [],
      stepCount,
    };
  }
  return metaInfo;
};

export const isPrivateworkflow = (workflow) => workflow?.sharedWithOrganisation?.toLowerCase().includes('private');

export const isOthersPrivateworkflow = (workflow, loggedInUserId) => isPrivateworkflow(workflow) && (getPropertyValue('userId', workflow) !== loggedInUserId);

export const canAddProspectsInWorkflow = (workflow, loggedInUserId) => {
  const { canAssignWorkflow, userId } = workflow;
  return canAssignWorkflow && (isPrivateworkflow(workflow) ? loggedInUserId === userId : true);
};

function generateQueryParams(url) {
  const qparams = {};
  const parts = (url || '').split('?');
  let qpart;
  let i = '';

  if (parts.length <= 1) {
    return qparams;
  }
  const qparts = parts[1].split('&');
  // eslint-disable-next-line
  for (i in qparts) {
    qpart = qparts[i].split('=');
    qparams[decodeURIComponent(qpart[0])] = decodeURIComponent(qpart[1] || '');
  }

  return qparams;
}

const getNameFromUserId = ({ userMap, userId, loginUser }) => (userId === loginUser ? 'You' : isObject(userMap[userId]) && userMap[userId].ownerName);

function isValidURL(string) {
  // eslint-disable-next-line
  const res = string.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
  return (res !== null);
}

export function isSecureUrl(string) {
  // eslint-disable-next-line
  const res = string.match(/(https:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
  return (res !== null);
}

const isUserAdmin = (user) => {
  let isAdmin = false;
  if (isObject(user) && user.roleAndProfile) {
    const { governanceProfile } = user.roleAndProfile;
    if (governanceProfile.toLowerCase() === 'admin') isAdmin = true;
  }
  return isAdmin;
};

function numToSSColumn(num, small = true) {
  let s = ''; let
    t;
  const charCode = small ? 97 : 65;

  while (num > 0) {
    t = (num - 1) % 26;
    s = String.fromCharCode(charCode + t) + s;
    // eslint-disable-next-line
    num = (num - t) / 26 | 0;
  }
  return s || undefined;
}

const replacePlaceholdersWithValues = ({ text, data }) => {
  let replaceStr = text || '';
  if (isObject(data)) {
    Object.keys(data)
      .forEach((field) => {
        const searchRegExp = new RegExp(`{${field}}`, 'g');
        const value = String(data[field]);
        replaceStr = replaceStr.replace(searchRegExp, value
          ? value.replace(/[<>]/gi, '')
          : '');
      });
  }
  return replaceStr;
};

const generateAssignee = (ownerOptions, currentUser) => {
  if (isObject(ownerOptions)) {
    const userCurrent = {};
    Object.keys(ownerOptions).map((owner) => {
      if (currentUser === ownerOptions[owner].basicInfo.email) {
        userCurrent.label = generateName(ownerOptions[owner].basicInfo);
        userCurrent.value = owner;
      }
      return null;
    });
    return userCurrent;
  }
  return null;
};

const getCodeAndUsername = (url) => {
  const splittedArr = url.split('&');
  return { confirmationCode: splittedArr[0]?.split('code=')[1], confirmationEmail: splittedArr[splittedArr.length - 2]?.split('email=')[1], confirmationType: splittedArr[splittedArr.length - 1]?.split('confirmationType=')[1] };
};

const getEmailAndSource = (url) => {
  const splittedArr = url.split('&');
  return { confirmationEmail: splittedArr[0]?.split('email=')[1], source: splittedArr[1]?.split('source=')[1] };
};

const getCodeAndUsernameForPassReset = (url) => {
  const splittedArr = url.split('&');
  return { confirmationCode: splittedArr[0]?.split('code=')[1], confirmationEmail: splittedArr[splittedArr.length - 1]?.split('email=')[1] };
};

const getPhoneNumberList = (dataObject, prospectCustomFields) => {
  const numbers = [];
  if (isObject(dataObject)) {
    // ORDERED INSERTION OF PHONE NUMBERS
    ['personalPhone', 'workPhone', 'otherPhone'].forEach((d) => {
      if (dataObject[d]) {
        numbers.push(dataObject[d]);
      }
    });
    // Object.keys(dataObject).forEach((d) => {
    //   if (d.toLowerCase().includes('phone') && dataObject[d]) numbers.push(dataObject[d]);
    // });
    (prospectCustomFields || []).forEach((d) => {
      if (d.fieldMetaInfo.type.toLowerCase() === 'phone' && dataObject.customFields?.[d.id]) numbers.push(dataObject.customFields[d.id]);
    });
  }
  return numbers;
};

export const checkProspectUnsubscribedStatus = ({ prospect = {}, type, needToCheckEmailBounce = false }) => {
  if (needToCheckEmailBounce && prospect.isLastEmailBounced) return true;
  if (type === SUBSCRIBE_TYPES[0]) return prospect.stage === DEFAULT_PROSPECT_STAGES[0];
  if (type === SUBSCRIBE_TYPES[1]) return prospect.stage === DEFAULT_PROSPECT_STAGES[1];
  return DEFAULT_PROSPECT_STAGES.includes(prospect.stage);
};

export const getUnBouncedProspect = (data = []) => data.filter((d) => !d.isLastEmailBounced);

const generateToOptionsFromProspectList = ({
  prospectList, labelRequired = true, allowedBulkActions = false, userSettings = {},
}) => {
  const resp = [];
  getUnBouncedProspect((prospectList || [])).forEach((d) => {
    if (!checkProspectUnsubscribedStatus({ prospect: d }) && (allowedBulkActions || d.userId === userSettings.id)) {
      let obj = {};
      if (labelRequired) {
        obj = { label: `${d.prospectName} <${d.prospectEmail}>`, value: { email: d.prospectEmail, name: d.prospectName } };
      } else {
        obj = { email: d.prospectEmail, name: d.prospectName };
      }
      resp.push(obj);
    }
  });
  return resp;
};
const generateToOptionsFromProspectMap = ({
  prospectMap, allowedBulkActions = false, userSettings = {},
}) => {
  const resp = [];
  if (isObject(prospectMap)) {
    Object.keys(prospectMap).forEach((d) => {
      if (!checkProspectUnsubscribedStatus({ prospect: prospectMap[d], needToCheckEmailBounce: true }) && (allowedBulkActions || prospectMap[d].userId === userSettings.id) && !prospectMap[d].isBlacklisted) {
        resp.push({
          label: `${prospectMap[d].prospectName} <${d}>`,
          value: { email: d, name: prospectMap[d].prospectName },
        });
      }
    });
  }
  return resp;
};

export const isUserVerifiedAndActive = ({ isActive, isVerified }) => isActive && isVerified;

const generateToOptionsFromUsersList = (items) => {
  const toOptions = [];
  (items || []).forEach((d) => {
    if (isUserVerifiedAndActive(d)) {
      toOptions.push({
        label: `${d.ownerName} <${d.email}>`,
        value: {
          name: d.ownerName,
          email: d.email,
        },
        data: d,
      });
    }
  });
  return toOptions;
};

const generateToOptionsFromUserMap = (userMap) => {
  const toOptions = [];
  Object.values(userMap).forEach((d) => {
    if (isUserVerifiedAndActive(d)) {
      toOptions.push({
        label: `${d.ownerName} <${d.email}>`,
        value: {
          name: d.ownerName,
          email: d.email,
        },
      });
    }
  });
  return toOptions;
};

const closeDropdownListener = (evt, elementIds, callback, classNames = []) => {
  let targetElement = evt.target; // clicked element
  let flyoutElements = elementIds.map((elementId) => document.getElementById(elementId));
  flyoutElements = flyoutElements.concat(classNames.map((className) => document.getElementsByClassName(className)[0]));
  do {
    if (flyoutElements.includes(targetElement)) {
      return;
    }
    targetElement = targetElement.parentNode;
  } while (targetElement);
  callback();
};

const guid = () => {
  function _p8(s) {
    const p = (`${Math.random().toString(16)}000000000`).substr(2, 8);
    return s ? `-${p.substr(0, 1)}-${p.substr(4, 5)}` : p.substring(0, 2);
  }
  return (_p8() + _p8(true) + new Date().toISOString().slice(0, 10)).replace(/-/g, '');
};

const createUnSubscribedContent = ({ mailbox = {}, accountObj = {} }) => {
  let resp = '';
  if (accountObj) {
    const { organizationSettings = {} } = accountObj;
    const isRequired = organizationSettings.admin && organizationSettings.admin['Add Unsubscribe Language to Bulk Emails'];
    if (isRequired) {
      const title = mailbox?.unsubscribeSettings?.title || '<a target="_blank" rel="noopener noreferrer" href="{{{unsubscribe_url}}}" >unsubscribe</a>';
      resp = `<div fr-original-class="${UNSUBSCRIBE_CONTENT_CLASSNAME}">${title}</div>`;
    }
  }
  return resp;
};

const getUnSubscribedProspects = (data = []) => data.filter((d) => !checkProspectUnsubscribedStatus({ prospect: d }));

const getUnOptedOutProspect = (data = []) => data.filter((d) => !checkProspectUnsubscribedStatus({ prospect: d, type: SUBSCRIBE_TYPES[1] }) && !d.isBlacklisted);
const getNonblacklistedProspects = (data = []) => data.filter((d) => !d.isBlacklisted);

const getValidProspectsList = ({
  prospects = [], isRequiredFullValue = false, isAllowedBulkActions = false, userId,
}) => {
  const unSubscribedProspects = [];
  const prospectList = [];
  prospects.forEach((d) => {
    if (!checkProspectUnsubscribedStatus({ prospect: d, type: SUBSCRIBE_TYPES[1] }) && (isAllowedBulkActions || d.userId === userId)) {
      prospectList.push({
        label: d.prospectName,
        value: isRequiredFullValue ? d : d.prospectEmail,
      });
      if (checkProspectUnsubscribedStatus({ prospect: d, type: SUBSCRIBE_TYPES[0], needToCheckEmailBounce: true })) {
        unSubscribedProspects.push(d.prospectEmail);
      }
    }
  });
  return { prospectList, unSubscribedProspects };
};
const needInfo = (fieldMapperArr, body) => fieldMapperArr.find((field) => body.includes(`{{${field}}}`));

const formatTaskScreenEmailBeforeSend = (text = '') => {
  let formattedText = text;
  formattedText = formattedText.replaceAll(`${AVAIALABLE_VARIABLE_COLOR}`, '');
  return formattedText;
};

const handleVariableFieldReplacementForEmailTask = (fieldValue, variableField, textToBeParsed, randomHash) => {
  const modText = textToBeParsed.split(randomHash);
  let bodyText = modText[1];
  let subjectText = modText[0];
  // if (bodyText.includes(`<span style="background-color: ${MISSING_VARIABLE_COLOR};">${variableField}</span>`)) {
  //   bodyText = bodyText.replaceAll(`<span style="background-color: ${MISSING_VARIABLE_COLOR};">${variableField}</span>`, variableField);
  // }
  subjectText = subjectText.replaceAll(variableField, fieldValue || variableField);
  bodyText = bodyText.replaceAll(variableField, fieldValue ? `<span style="background-color: ${AVAIALABLE_VARIABLE_COLOR};">${fieldValue || variableField}</span>` : variableField);
  // bodyText = bodyText.replaceAll(variableField, `<span style="background-color: ${fieldValue ? AVAIALABLE_VARIABLE_COLOR : MISSING_VARIABLE_COLOR};">${fieldValue || variableField}</span>`);
  return subjectText + randomHash + bodyText;
};

export const handleVariableFieldReplacement = (fieldValue, variableField, text = '') => text.replaceAll(variableField, `<span class=${fieldValue ? 'valid-variable-field' : 'invalid-variable-field'}>${fieldValue || variableField}</span>`);

export const generateCustomFieldOptions = ({ fields, prefix }) => fields.map((d) => {
  const { fieldName } = d;
  return `${prefix}.${camelCase(fieldName)}`;
});

const replaceVariables = (data, body = '', subject = '', prospectCustomFields, accountCustomFields, isEmailTask = false) => {
  const randomHash = guid();
  let textToBeParsed = subject + randomHash + body;
  let hasUnavailableField = false;
  [...PROSPECT_FIELD_MAPPER, ...ACCOUNT_FIELD_MAPPER, ...SENDER_MAPPER, ...generateCustomFieldOptions({ fields: prospectCustomFields, prefix: CUSTOM_FIELD_PLACEHOLDER_PREFIX_MAP.prospect }),
    ...generateCustomFieldOptions({ fields: accountCustomFields, prefix: CUSTOM_FIELD_PLACEHOLDER_PREFIX_MAP.account })]
    .forEach((field) => {
      const variableField = `{{${field}}}`;
      const fieldValue = data[DYNAMIC_FIELD_MAPPER[field] || field];
      textToBeParsed = isEmailTask ? handleVariableFieldReplacementForEmailTask(fieldValue, variableField, textToBeParsed, randomHash)
        : handleVariableFieldReplacement(fieldValue, variableField, textToBeParsed);
      if (!hasUnavailableField) {
        if (textToBeParsed.includes(variableField)) hasUnavailableField = true;
      }
    });
  const parsedTextArray = textToBeParsed.split(randomHash);
  return { subject: parsedTextArray[0], body: parsedTextArray[1], hasUnavailableField };
};

export const convertNumberToCurrencyFormat = ({
  currency = 'USD', numberToBeFormatted = 0, compactNotation = true, extraProp = {},
}) => {
  const isCurrencySGD = currency === 'SGD';
  const formatter = new Intl.NumberFormat(CURRENCY_FORMAT_MAP[currency] || 'en-US', {
    style: 'currency',
    currency: isCurrencySGD ? 'USD' : currency,
    notation: compactNotation ? 'compact' : 'standard',
    ...extraProp,
  });
  let returnValue = formatter.format(numberToBeFormatted);
  if (isCurrencySGD) {
    returnValue = returnValue.replace('$', 'S$');
  }
  return returnValue;
};

export const getOrgDefaultCurrency = (accountSettings) => accountSettings?.organizationSettings.currency?.['ORGANIZATION DEFAULT CURRENCY'];

export const generateCurrencyString = ({ currency = '', value = '' }) => convertNumberToCurrencyFormat({ currency, numberToBeFormatted: value });

export const computeEmailVariableForCustomFields = ({ type, value, orgCurrency }) => {
  switch (type) {
    case CUSTOM_FIELD_TYPE_ENUM.Text:
    case CUSTOM_FIELD_TYPE_ENUM.Email:
    case CUSTOM_FIELD_TYPE_ENUM.URL:
    case CUSTOM_FIELD_TYPE_ENUM.Number:
    case CUSTOM_FIELD_TYPE_ENUM.Phone:
    case CUSTOM_FIELD_TYPE_ENUM.List:
    case CUSTOM_FIELD_TYPE_ENUM.LongText:
    case CUSTOM_FIELD_TYPE_ENUM.Boolean:
    {
      return value;
    }
    case CUSTOM_FIELD_TYPE_ENUM.Percentage: {
      return value ? `${value}%` : value;
    }
    case CUSTOM_FIELD_TYPE_ENUM.MultiList: {
      return isArray(value) ? showItemList(value) : '';
    }
    case CUSTOM_FIELD_TYPE_ENUM.Date: {
      return isValidDate(value) ? format(new Date(value), 'PP') : '';
    }
    case CUSTOM_FIELD_TYPE_ENUM.Amount:
      return (isObject(value) && orgCurrency) ? generateCurrencyString({ ...value, currency: orgCurrency }) : '';
    default:
      return '';
  }
};
export const generateCustomFieldPlaceholderMap = ({
  fields, entityObject, prefix, orgCurrency,
}) => {
  const fieldMap = {};
  if (isObject(entityObject) && entityObject.customFields) {
    fields.forEach((d) => {
      const { fieldName, id, fieldMetaInfo: { type } } = d;
      if (entityObject.customFields[id]) {
        fieldMap[`${prefix}.${camelCase(fieldName)}`] = computeEmailVariableForCustomFields({ value: entityObject.customFields[id], type, orgCurrency });
      }
    });
  }
  return fieldMap;
};

const generateComputedText = ({
  body = '', subject = '', userObject = {}, accountObject = {}, prospectObject = {}, prospectAccount = {},
  prospectCustomFields = [], accountCustomFields = [], isEmailTask = false, senderInfo = {},
}) => {
  const orgCurrency = getOrgDefaultCurrency(accountObject);
  let data = {};
  const prospectCustomFieldMap = generateCustomFieldPlaceholderMap({
    fields: prospectCustomFields,
    entityObject: prospectObject,
    prefix: CUSTOM_FIELD_PLACEHOLDER_PREFIX_MAP.prospect,
    orgCurrency,
  });
  const accountCustomFieldMap = generateCustomFieldPlaceholderMap({
    fields: accountCustomFields,
    entityObject: prospectAccount,
    prefix: CUSTOM_FIELD_PLACEHOLDER_PREFIX_MAP.account,
    orgCurrency,
  });
  data = { ...data, ...accountCustomFieldMap, ...prospectCustomFieldMap };
  if (needInfo(PROSPECT_FIELD_MAPPER, body) || needInfo(PROSPECT_FIELD_MAPPER, subject)) {
    data = { ...data, ...prospectObject };
  }
  if (needInfo(ACCOUNT_FIELD_MAPPER, body) || needInfo(ACCOUNT_FIELD_MAPPER, subject)) {
    const { name, accountDomain } = prospectAccount;
    data = { ...data, accountName: name, accountDomain };
  }
  if (needInfo(SENDER_MAPPER, body) || needInfo(SENDER_MAPPER, subject)) {
    const { roleAndProfile = {} } = userObject;
    const { organizationSettings = {} } = accountObject || {};
    const { basicInfo: accountBasicInfo = { Name: '' } } = organizationSettings;
    data = {
      ...data,
      ...{
        [SENDER_MAPPER[0]]: senderInfo.name,
        [SENDER_MAPPER[1]]: senderInfo.email,
        [SENDER_MAPPER[2]]: accountBasicInfo.Name,
        [SENDER_MAPPER[3]]: roleAndProfile.title,
      },
    };
  }
  return replaceVariables(data, body, subject, prospectCustomFields, accountCustomFields, isEmailTask);
};

const generateComputextForNonEmailTask = ({
  body = '', subject = '', prospectObject = {}, prospectCustomFields = [], accountCustomFields = [], prospectAccount, orgCurrency,
}) => {
  const prospectCustomFieldMap = generateCustomFieldPlaceholderMap({
    fields: prospectCustomFields,
    entityObject: prospectObject,
    prefix: CUSTOM_FIELD_PLACEHOLDER_PREFIX_MAP.prospect,
    orgCurrency,
  });
  const accountCustomFieldMap = generateCustomFieldPlaceholderMap({
    fields: accountCustomFields,
    entityObject: prospectAccount,
    prefix: CUSTOM_FIELD_PLACEHOLDER_PREFIX_MAP.account,
    orgCurrency,
  });
  const { name, accountDomain } = prospectAccount;
  const data = {
    ...prospectObject, ...accountCustomFieldMap, ...prospectCustomFieldMap, accountName: name, accountDomain,
  };
  const randomHash = guid();
  let textToBeParsed = subject + randomHash + body;
  let hasUnavailableField = false;
  [
    ...PROSPECT_FIELD_MAPPER,
    ...ACCOUNT_FIELD_MAPPER,
    ...generateCustomFieldOptions({ fields: prospectCustomFields, prefix: CUSTOM_FIELD_PLACEHOLDER_PREFIX_MAP.prospect }),
    ...generateCustomFieldOptions({ fields: accountCustomFields, prefix: CUSTOM_FIELD_PLACEHOLDER_PREFIX_MAP.account }),
  ].forEach((field) => {
    const variableField = `{{${field}}}`;
    const fieldValue = data[DYNAMIC_FIELD_MAPPER[field] || field];
    textToBeParsed = textToBeParsed.replaceAll(variableField, fieldValue || variableField);
    if (!hasUnavailableField && textToBeParsed.includes(variableField)) hasUnavailableField = true;
  });
  const parsedTextArray = textToBeParsed.split(randomHash);
  return { subject: parsedTextArray[0], body: parsedTextArray[1], hasUnavailableField };
};

const removeInsightsDataFromPayload = (arr, lastUsedTemplateOveride = false) => arr
  .map((step) => {
    let { templates: stepTemplates } = step;
    if (isArray(stepTemplates)) {
      stepTemplates = stepTemplates.map((t) => {
        const { insights, ...restTemplate } = t;
        return restTemplate;
      });
    }
    if (lastUsedTemplateOveride) {
      return cleanObject({ ...step, templates: stepTemplates, lastUsedTemplate: -1 });
    }
    return cleanObject({ ...step, templates: stepTemplates });
  });

const hexToRgb = (hex) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16),
  } : null;
};

const getTextColorForStage = (backgroundColor) => {
  let rgb;
  if (backgroundColor.includes('#')) {
    const incomingColor = hexToRgb(backgroundColor);
    rgb = incomingColor && Object.values(incomingColor);
  } else {
    rgb = backgroundColor.match(/\d+/g);
  }
  return rgb ? (rgb[0] * 0.299 + rgb[1] * 0.587 + rgb[2] * 0.114) > 186 ? '#565857' : '#fff' : '#FFF';
};

const checkContentExceedLength = (text, MAX_LENGTH) => MAX_LENGTH <= text.length;

const truncateText = (text, MAX_LENGTH) => `${text.substr(0, MAX_LENGTH - 3)}...`;

const debounce = (func, delay) => {
  let inDebounce;
  // eslint-disable-next-line func-names
  return function () {
    const context = this;
    // eslint-disable-next-line prefer-rest-params
    const args = arguments;
    clearTimeout(inDebounce);
    inDebounce = setTimeout(() => func.apply(context, args), delay);
  };
};

const UNSUBSCRIBE_REGEX = /<div fr-original-class="${UNSUBSCRIBE_CONTENT_CLASSNAME}".*?<a .*?href="{{{unsubscribe_url}}}".*?<\/a><\/div>/g;
export const getUnsubscribeLink = (newBody) => {
  const idx = newBody.lastIndexOf(`<div fr-original-class="${UNSUBSCRIBE_CONTENT_CLASSNAME}`);
  if (idx !== -1) return newBody.substr(idx);
  return '';
};

export const hasUnsubscribeLink = (newBody) => UNSUBSCRIBE_REGEX.test(newBody);

const getCRMIcon = ({
  hubspotObjectId, pipedriveObjectId, salesforceObjectId, zohoObjectId,
}, syncedCRM) => {
  let resp = '';
  switch (syncedCRM) {
    case PLUGIN_ENUM[0]:
      resp = hubspotObjectId ? 'hubspotSyncIcon' : 'hubspotUnsyncIcon';
      break;
    case PLUGIN_ENUM[1]:
      resp = pipedriveObjectId && (pipedriveObjectId).toString().trim() ? 'pipedriveSync' : 'pipedriveUnsync';
      break;
    case PLUGIN_ENUM[2]:
      resp = salesforceObjectId && (salesforceObjectId).toString().trim() ? 'salesforceSync' : 'salesforceUnsync';
      break;
    case PLUGIN_ENUM[3]:
      resp = zohoObjectId && (zohoObjectId).toString().trim() ? 'zohoSyncIcon' : 'zohoUnsyncIcon';
      break;
    default:
      break;
  }
  return resp;
};

export const getCRMSyncText = (profileInfo = {}, syncedCRM) => {
  const crmIcon = getCRMIcon(profileInfo, syncedCRM);
  switch (syncedCRM) {
    case PLUGIN_ENUM[0]: case PLUGIN_ENUM[1]:
      return crmIcon.toLowerCase().includes('unsync') ? 'Unsynced' : 'Synced';
    case PLUGIN_ENUM[2]:
      return crmIcon.toLowerCase().includes('unsync') ? 'Unsynced' : `${!(profileInfo?.salesforceLeadId || profileInfo?.salesforceContactId) ? 'Synced' : `Synced with ${profileInfo?.salesforceContactId ? 'Contact' : 'Lead'}`}`;
    case PLUGIN_ENUM[3]:
      return crmIcon.toLowerCase().includes('unsync') ? 'Unsynced' : `${!(profileInfo?.zohoLeadId || profileInfo?.zohoContactId) ? 'Synced' : `Synced with ${profileInfo?.zohoContactId ? 'Contact' : 'Lead'}`}`;
    default:
      return 'Unsynced';
  }
};

export const changeTemplateHTMLtoPreviewHTML = (htmlBody) => htmlBody;

export const checkElipsisActive = (elementId) => {
  const el = document.getElementById(elementId);
  if (el) return el.offsetWidth < el.scrollWidth;
  return false;
};

export const getClickableLink = (link) => (link.startsWith('http://') || link.startsWith('https://')
  ? link
  : `https://${link}`);

export const noOptionsMessageHelper = (val, fieldType) => (val.inputValue ? `Create "${val.inputValue}"` : `Type and enter to create new ${fieldType}`);

export const noOptionsMessageHelperForSearch = (fieldType) => `Type and search ${fieldType.toLowerCase()}`;

export const fireEventOnEnter = (e, callback) => {
  if (e.keyCode === 13 && isFunction(callback)) {
    callback(e);
  }
};

export const getOnlyModifiedProps = (newProps, oldProps) => {
  const changes = {};
  if (!(isObject(newProps) && isObject(oldProps))) return changes;
  const totalKeys = Array.from(new Set([...Object.keys(newProps), ...Object.keys(oldProps)]));
  totalKeys.forEach((d) => {
    const propertyObjA = newProps[d];
    const propertyObjB = oldProps[d];
    if (isChangedObject(propertyObjA, propertyObjB)) {
      changes[d] = propertyObjA;
    }
  });
  return changes;
};

export const generateLoggedInUserAssignee = (ownerOptions, currentUser) => {
  const resp = generateAssignee(ownerOptions, currentUser);
  if (resp) resp.label = `${resp.label} (You)`;
  return resp;
};

const createUserOption = ({ user, reqOnlyActiveUsers, loggedInUserId }) => {
  if (reqOnlyActiveUsers ? (isUserVerifiedAndActive(user)) : user.isVerified) {
    return {
      label: `${generateName(user.basicInfo)}${user.id === loggedInUserId ? ' (You)' : ''}`,
      value: user.id,
    };
  }
  return null;
};

export const generateOwnerOptions = ({ allUsers = [], userId, reqOnlyActiveUsers = true }) => {
  const activeUsers = [];
  allUsers.forEach((user) => {
    const option = createUserOption({
      user, reqOnlyActiveUsers, loggedInUserId: userId,
    });
    option && activeUsers.push(option);
  });
  return activeUsers;
};

export const generateOwnerOptionsFromMap = ({ userMap = {}, userId, reqOnlyActiveUsers = true }) => {
  const activeUsers = [];
  Object.values(userMap).forEach((user) => {
    const option = createUserOption({
      user, reqOnlyActiveUsers, loggedInUserId: userId,
    });
    option && activeUsers.push(option);
  });
  return activeUsers;
};

const removeHtmlTagsFromString = (strToBeFormatted = '') => {
  let str = strToBeFormatted;
  if (str) {
    const regex = /<br fr-original-style="" style="box-sizing: inherit;">/ig;
    str = str.replaceAll(regex, ' ');
    str = str.replace(/(<([^>]+)>)/ig, '');
  }
  return str;
};

export const removeHtmlTagsFromStringV2 = (strToBeFormatted = '') => {
  const div = document.createElement('div');
  div.innerHTML = strToBeFormatted;
  return div.innerText;
};

export const isCreatedOption = (dataObject) => {
  const { value } = dataObject;
  return isObject(value) ? dataObject : { ...dataObject, value: { prospectEmail: value }, data: { prospectEmail: value } };
};

export const insertFieldInSubject = ({ subject, elementId, field }) => {
  const el = document.getElementById(elementId);
  const idx = el.selectionStart;
  let resp = `{{${field}}}`;
  if (subject) {
    resp = `${(subject).slice(0, idx)}${resp}${(subject).slice(idx)}`;
  }
  el.focus();
  return resp;
};

export const addPlaceholderInSubject = ({
  elementId, subject, field, setTemplateObject, templateObject,
}) => {
  setTemplateObject({
    ...templateObject,
    subject: insertFieldInSubject({ subject, elementId, field }),
  });
};

export const sortFieldsWithOrder = ({ dataItems = [], itemsOrder = [] }) => dataItems.sort((a, b) => itemsOrder.indexOf(a.id) - itemsOrder.indexOf(b.id));

export const checkValidationForEditSetting = ({
  basicInfo, setError, REQUIRED_FIELDS, prevData = {},
}) => {
  const errorFields = {};
  let errorExist = false;
  REQUIRED_FIELDS.forEach((d) => {
    const err = d.validation(basicInfo, prevData);
    if (err) {
      errorFields[d.field] = err;
      if (!errorExist) errorExist = true;
    }
  });
  setError(errorFields);
  return errorExist;
};

export const getColorCodeForWorkflowProspectStatus = (assignedWorkflow) => {
  const { status, isReplied } = assignedWorkflow;
  const statusOverride = status === WORKFLOW_PROSPECT_STATUS_MAP.finished && isReplied ? 'finishedReplied' : 'finishedNoReply';
  return WORKFLOW_PROSPECT_STATUS_COLOR_CODE[statusOverride] || WORKFLOW_PROSPECT_STATUS_COLOR_CODE.default;
};

export const replaceSenderVariablesForTestEmail = (subject = '', body = '', footerContent = '') => {
  const randomHash = guid();
  let newBody = subject + randomHash + body;
  [
    { field: 'firstName', senderField: 'sender.firstName' },
    { field: 'lastName', senderField: 'sender.lastName' },
    { field: 'jobTitle', senderField: 'sender.title' },
    { field: 'organisation', senderField: 'sender.company' },
    { field: 'fullName', senderField: 'sender.name' },
  ].forEach((d) => {
    const variableField = `{{${d.field}}}`;
    newBody = newBody.replaceAll(variableField, `{{${d.senderField}}}`);
  });
  const modArr = newBody.split(randomHash);
  return { subject: modArr[0], body: modArr[1] + footerContent };
};

export const checkValidationForImportCSVRows = ({ rows, rowIndex, validator }) => {
  const items = rows.map((row) => row[rowIndex]);
  let invalidValues = 0;
  items.forEach((d) => {
    if (d && !validator(d)) {
      invalidValues += 1;
    }
  });
  return invalidValues === 0 || !(invalidValues === items.length);
};

export const getFirstAndLastTimeBlockDayFromSchedule = ({ timeBlocks = {} }) => {
  const modifiedDays = [...days.slice(1), days[0]];
  const startDay = modifiedDays.find((d) => timeBlocks[d].length);
  let lastDay;
  modifiedDays.forEach((d) => {
    if (timeBlocks[d].length) lastDay = d;
  });
  if (startDay !== lastDay) return [startDay, lastDay].join(' - ');
  return startDay;
};

export const escapeTextValue = (textValue = '') => `${textValue}`.replace(/&/g, '&amp;')
  .replace(/</g, '&lt;')
  .replace(/>/g, '&gt;')
  .replace(/"/g, '&quot;')
  .replace(/'/g, '&#x27');
// .replace(/\//g, '&#x2F');

export const sameNameValidation = ({ data = {}, itemList = [], entity = '' }) => {
  let err = '';
  if (data.name?.trim()) {
    const entityWithNameExist = itemList.find((d) => d.name && d.name.toLowerCase().trim() === data.name.toLowerCase().trim());
    if (entityWithNameExist && entityWithNameExist.id !== data.id) err = `${escapeTextValue(entity)} name must be unique`;
  } else err = `${escapeTextValue(entity)} name required`;
  return err;
};
const selectColor = (number) => {
  const hue = number * 127.508; // use golden angle approximation
  return `hsl(${hue}, 58.6%, 61.2%)`;
};

export const removeSelectedStagesFromStageArr = ({ stages, stagesToBeRemoved, valueKey = 'value' }) => {
  const resp = [];
  stages.forEach((d) => {
    if (!stagesToBeRemoved.includes(d[valueKey])) {
      resp.push(d);
    }
  });
  return resp;
};

export const isEmailStepType = (taskName) => [TASK_TYPE_ENUM['Auto Email'], TASK_TYPE_ENUM['Manual Email']].includes(taskName);
export const checkIfWorkflowHasEmailStep = ({ steps = [] }) => steps.find((d) => isEmailStepType(d.task.name));
const deepMerge = (obj1, obj2) => Object.keys(obj1).reduce((acc, key) => {
  if (typeof obj2[key] === 'object' && obj2[key] !== null) {
    acc[key] = deepMerge(obj1[key], obj2[key]);
    /* eslint-disable no-prototype-builtins */
    // eslint-disable-next-line no-restricted-globals
  } else if (obj2.hasOwnProperty(key) && !isNaN(parseFloat(obj2[key]))) {
    acc[key] = obj1[key] + obj2[key];
  }
  return acc;
}, obj1);

const getPlainTextFromHtml = (html) => {
  const span = document.createElement('span');
  // https://www.stackhawk.com/blog/react-xss-guide-examples-and-prevention/
  // TODO XSS ATTACK: Remove this
  span.innerHTML = html;
  return span.innerText.replace(/\s/g, '');
};

const NYLAS_TRACKING_IMG_REGEX = /"https:\/\/(t.nylas.com|nyl.as|\w+.nyl.as)\/open\/.*?"/g;
export const removeNylasTrackingImage = (emailInfo = []) => emailInfo.map((d) => ({
  ...d, body: (d.body || '').replaceAll(NYLAS_TRACKING_IMG_REGEX, '""'),
}));

const triggerCloseModal = (buttonId = 'close-custom-modal') => {
  const el = document.getElementById(buttonId);
  if (el) {
    el.click();
  }
};

const checkIfWorkflowThrottleLimitBreached = ({ prospectCount }) => {
  let isThrottleBreached;
  if (prospectCount > MAX_PROSPECT_CAN_BE_ADDED_TO_WORKFLOW_AT_A_TIME) {
    isThrottleBreached = `Currently we do not allow assigning more than ${MAX_PROSPECT_CAN_BE_ADDED_TO_WORKFLOW_AT_A_TIME} prospects`;
  }
  return isThrottleBreached;
};

const changeSignatureContent = ({ body, signatureBody }) => {
  let htmlBody = body;
  const unsubscribeLink = getUnsubscribeLink(htmlBody);
  htmlBody = htmlBody.replace(unsubscribeLink, '');
  let n = htmlBody.lastIndexOf(`${SIGNATURE_STARTING_REGEX}`);
  if (n === -1) n = htmlBody.length;
  htmlBody = htmlBody.substring(0, n);
  htmlBody += (signatureBody ? `<p style="display: none;">--</p><div style="display: table;">${signatureBody}</div>` : '');
  htmlBody += `${signatureBody ? '<br>' : ''}${unsubscribeLink}`;
  return htmlBody;
};

const getUserTimeAndName = (date, name) => `On ${format(new Date(Number(date) * 1000), 'PPPPp')}, ${name}`;

const getBlockquote = (finalEmailArray) => {
  let preBuiltEmailbody = '';
  const finalObjIndex = finalEmailArray.length - 1;
  if (finalObjIndex >= 0) {
    const { from = [], body = '', date } = finalEmailArray[finalObjIndex];
    const { name, email } = from[0];
    const userTimeAndName = getUserTimeAndName(date, name);
    const senderInfo = `<div dir="ltr" class="gmail_attr">${userTimeAndName} &lt;<a href="mailto:${email}">${email}</a>&gt; wrote:</div>`;
    preBuiltEmailbody = `<br><div class="gmail_quote">${senderInfo}<blockquote class="gmail_quote" style="margin: 0px 0px 0px 0.8ex; border-left: 1px solid rgb(204, 204, 204); padding-left: 1ex; font-style: normal;">
  ${body}${getBlockquote(finalEmailArray.slice(finalObjIndex + 1))}</blockquote></div>`;
  }
  return preBuiltEmailbody;
};

export const getBlockquoteHTML = (emailInfo) => {
  let emailQouteText = '';
  const emailObj = emailInfo[emailInfo.length - 1];
  const finalEmailArray = [(emailObj.headers.References || []).map((messageId) => emailInfo.find((d) => d.headers['Message-Id'] === messageId)), emailObj];
  emailQouteText = `<br>${getBlockquote(finalEmailArray)}`;
  return emailQouteText;
};

export const findSignatureFromAlias = ({ emailAliases, aliasId }) => emailAliases?.[Object.keys(emailAliases).find((id) => id === aliasId)]?.defaultSignature;
export const getAppendedHTML = ({
  unSubscribeContent, isWorkflowtask = false, defaultEmailAlias = '', mailbox = {},
}) => {
  let appendedHtml = '';
  if (!isWorkflowtask) {
    let defaultSignatureId;
    const { signatures = {}, emailAliases = {}, defaultSignature = '' } = mailbox;
    if (isString(defaultEmailAlias)) {
      defaultSignatureId = findSignatureFromAlias({ emailAliases, aliasId: defaultEmailAlias }) || defaultSignature || NO_SIGNATURE;
    }
    const signatureObj = signatures?.[Object.keys(signatures).find((signatureId) => defaultSignatureId === signatureId)];
    if (signatureObj) {
      appendedHtml += `<br><p style="display: none;">--</p><div style="display: table;">${signatureObj.body}</div>`;
    }
    appendedHtml += (unSubscribeContent ? (`<br>${unSubscribeContent}`) : '');
  }
  return appendedHtml;
};

export const compareTaskBodyWithTemplateBody = ({
  template, task, mailbox, unSubscribeContent,
}) => {
  const { from, ...rest } = task;
  let bodyFooterHTML = getAppendedHTML({
    mailbox, unSubscribeContent, defaultEmailAlias: from,
  });
  if (bodyFooterHTML.startsWith('<br>')) bodyFooterHTML = bodyFooterHTML.substr(4);
  // eslint-disable-next-line no-param-reassign
  template.body += bodyFooterHTML;
  return isChangedObject(template, rest);
};

const checkIfSameTemplateUsedAndReturnOverridfeEmailObject = ({
  from, emailObject, accountSettings, templates = [],
  unSubscribeContent, mailbox,
}) => {
  let isChanged = false;
  const {
    template: templateUsed, subject, body, files, ...rest
  } = emailObject || {};
  if (templateUsed && templateUsed.id) {
    const templateObj = templates.find((d) => d.id === templateUsed?.id) || {};
    let usContent = unSubscribeContent;
    if (!usContent) usContent = createUnSubscribedContent({ accountObj: accountSettings, mailbox });
    isChanged = compareTaskBodyWithTemplateBody({
      template: {
        subject: templateObj.subject,
        body: templateObj.body,
        files: templateObj.files || [],
      },
      mailbox,
      task: {
        subject, body, files: files || [], from,
      },
      unSubscribeContent: usContent,
    });
  }
  if (isChanged || !templateUsed?.id) {
    return {
      ...rest, files, body, subject, template: templateUsed,
    };
  }
  return { ...rest, template: templateUsed };
};

const findTemplateAndPrepareEmailObjectIfBodyMissing = ({
  emailObject, templates = [], mailbox, unSubscribeContent, from, isWorkflowtask = false,
}) => {
  let overideEmailObject = { ...emailObject };
  if (overideEmailObject.template?.id && !overideEmailObject.body) {
    const templateObject = templates.find((d) => d.id === overideEmailObject.template.id);
    if (templateObject) {
      overideEmailObject = {
        ...overideEmailObject,
        body: templateObject.body + getAppendedHTML({
          mailbox, unSubscribeContent, defaultEmailAlias: from, isWorkflowtask,
        }),
        subject: templateObject.subject,
        files: templateObject.files,
      };
    }
  }
  return overideEmailObject;
};
// TODO: DO NOT ADD CONTENT IN THIS FILE, USE HELPER-2.UTILS.JS
export const getLinkedInTaskMessageLimit = (taskType) => {
  let maxCharactersAllowedBody = null;
  switch (taskType) {
    case TASK_TYPES[7]:
      maxCharactersAllowedBody = MAX_CHARACTERS_ALLOWED_FOR_LINKEDIN_MESSAGE;
      break;
    case TASK_TYPES[6]:
      maxCharactersAllowedBody = MAX_CHARACTERS_ALLOWED_FOR_LINKEDIN_CONNECTION_REQUEST;
      break;
    case TASK_TYPES[5]:
      maxCharactersAllowedBody = MAX_CHARACTERS_ALLOWED_FOR_LINKEDIN_INMAIL;
      break;
    case TASK_TYPES[10]:
      maxCharactersAllowedBody = MAX_CHARACTERS_ALLOWED_FOR_WHATSAPP_MESSAGE;
      break;
    default:
      break;
  }
  return maxCharactersAllowedBody;
};

export const calcPercent = (count = 0, outOf) => (outOf ? Math.round((count / outOf) * 100) : 0);

export const getPercentage = (count = 0, outOf) => `${calcPercent(count, outOf)}%`;

export const getMaxWidthInPx = (d) => {
  const screenWidth = window.screen.availWidth;
  const pixWidth = (d * screenWidth) / 100;
  if (screenWidth > 1500) {
    return (pixWidth * 1544) / 1440;
  }
  return `${pixWidth}px`;
};

export const truncateLeadingZerosOfNumberString = (value) => {
  if (value && typeof value === 'string') {
    return value.replace(/^0+/, '');
  }
  return value;
};

export const isNumberValid = (value) => {
  const regex = /^\d+$/; // Regex to check if only numbers are inputed.
  return regex.test(value);
};

export const scrollToTop = (elementId) => {
  const element = document.getElementById(elementId);
  if (element) {
    element.scrollTop = 0;
  }
};

export {
  cleanObject, triggerCloseModal, checkIfWorkflowThrottleLimitBreached,
  generateProfileTagName, validateForm, makeOptions, validateFormWithNestedObj,
  ifAnyError, constructName, allUsersObject, generateNameWithoutCapitalizing,
  isChangedObject, makeAllOptions, makeOwnerName,
  generateMetaInfoForWorkflow, generateName, generateQueryParams,
  getNameFromUserId, isValidURL, isUserAdmin,
  numToSSColumn, replacePlaceholdersWithValues, generateAssignee, getCodeAndUsername, getEmailAndSource,
  getTotalDays, getTotalDaysForExactTime, average, getPhoneNumberList, generateToOptionsFromProspectList,
  generateToOptionsFromUsersList, closeDropdownListener, guid, getUnSubscribedProspects,
  getUnOptedOutProspect, getNonblacklistedProspects, createUnSubscribedContent, getValidProspectsList, getCodeAndUsernameForPassReset,
  generateComputedText, needInfo, removeInsightsDataFromPayload, getTextColorForStage, checkContentExceedLength,
  truncateText, debounce, generateToOptionsFromProspectMap, getCRMIcon, removeHtmlTagsFromString, formatTaskScreenEmailBeforeSend,
  selectColor, deepMerge, getPlainTextFromHtml, generateToOptionsFromUserMap, changeSignatureContent,
  checkIfSameTemplateUsedAndReturnOverridfeEmailObject, findTemplateAndPrepareEmailObjectIfBodyMissing, generateComputextForNonEmailTask,
};
