import {
  ACCOUNT_SETTINGS, ACCOUNT_SETTINGS_SUCCESS,
  ACCOUNT_SETTINGS_FAILURE, UPDATE_ACCOUNT_TAGS, UPDATE_ACCOUNT_SETTING, UPDATE_ACCOUNT_EXCLUDED_DOMAINS, UPDATE_ACCOUNT_CREDITS,
} from '../action/type';

const initialState = {
  accountId: '', accountSettings: null, isLoading: NaN, error: '',
};
export const accountSettingsReducer = (curState = initialState, action = { type: '', payload: '' }) => {
  let newState;
  switch (action.type) {
    case ACCOUNT_SETTINGS:
      newState = {
        ...curState,
        error: null,
        isLoading: ACCOUNT_SETTINGS,
        accountId: action.payload,
      };
      break;
    case ACCOUNT_SETTINGS_SUCCESS:
      newState = {
        ...curState,
        isLoading: ACCOUNT_SETTINGS_SUCCESS,
        accountSettings: action.payload.accountSettings,
      };
      break;
    case ACCOUNT_SETTINGS_FAILURE:
      newState = {
        ...curState,
        isLoading: ACCOUNT_SETTINGS_FAILURE,
        accountId: '',
        error: action.payload,
      };
      break;
    case UPDATE_ACCOUNT_TAGS:
      newState = {
        ...curState,
        isLoading: UPDATE_ACCOUNT_TAGS,
        accountSettings: { ...(curState.accountSettings || {}), tags: action.payload.tags },
      };
      break;
    case UPDATE_ACCOUNT_CREDITS:
      newState = {
        ...curState,
        isLoading: UPDATE_ACCOUNT_CREDITS,
        accountSettings: { ...(curState.accountSettings || {}), upscaleCredits: action.payload.upscaleCredits, creditsLastUpdatedOn: action.payload.creditsLastUpdatedOn },
      };
      break;
    case UPDATE_ACCOUNT_EXCLUDED_DOMAINS:
      newState = {
        ...curState,
        isLoading: UPDATE_ACCOUNT_EXCLUDED_DOMAINS,
        accountSettings: { ...(curState.accountSettings || {}), domainsExcludedFromReplyDetection: action.payload.domainsExcludedFromReplyDetection },
      };
      break;
    case UPDATE_ACCOUNT_SETTING:
      newState = {
        ...curState,
        isLoading: UPDATE_ACCOUNT_SETTING, // Why are we using this tag over here
        accountSettings: { ...(curState.accountSettings || {}), ...action.payload },
      };
      break;
    case 'LAST_REPORT_GENERATED_ON':
      newState = {
        ...curState,
        isLoading: 'LAST_REPORT_GENERATED_ON', // Why are we using this tag over here
        accountSettings: { ...(curState.accountSettings || {}), ...action.payload },
      };
      break;
    default:
      newState = curState;
  }

  return newState;
};
