import { CUSTOM_FIELD_TYPE_ENUM } from '.';

export const OPERAND_ENUM = {
  is: 1, // string, boolean
  isNot: 2, // string
  isAnyOf: 3,
  isNoneOf: 4,
  contains: 5,
  doesNotContains: 6,
  isEmpty: 7,
  isNotEmpty: 8,
  '=': 9,
  '!=': 10,
  '<': 11,
  '>': 12,
  isBetween: 13,
  isWithin: 14,
  isBefore: 15,
  isAfter: 16,
  isOnOrBefore: 17,
  isOnOrAfter: 18,
  'is Before': 11,
  'is After': 12,
};

export const REVERSE_OPERAND_ENUM = {};
Object.keys(OPERAND_ENUM).forEach((d) => {
  REVERSE_OPERAND_ENUM[OPERAND_ENUM[d]] = d;
});

export const ADVANCE_FILTER_HTML_ENUM = {
  textA: 'textA',
  textB: 'textB',
  textC: 'textC',
  listA: 'listA',
  listB: 'listB',
  listC: 'listC',
  numberA: 'numberA',
  numberB: 'numberB',
  dateA: 'dateA',
  dateB: 'dateB',
  currency: 'currency',
  boolean: 'boolean',
  asyncList: 'asyncList',
  timeRange: 'timeRange',
};

export const conditionMapping = {
  textA: [OPERAND_ENUM.is, OPERAND_ENUM.isNot, OPERAND_ENUM.isAnyOf,
    OPERAND_ENUM.isNoneOf, OPERAND_ENUM.isEmpty, OPERAND_ENUM.isNotEmpty],
  textB: [OPERAND_ENUM.is, OPERAND_ENUM.isNot, OPERAND_ENUM.contains,
    OPERAND_ENUM.doesNotContains, OPERAND_ENUM.isEmpty, OPERAND_ENUM.isNotEmpty],
  textC: [OPERAND_ENUM.is, OPERAND_ENUM.isNot, OPERAND_ENUM.contains,
    OPERAND_ENUM.doesNotContains, OPERAND_ENUM.isEmpty, OPERAND_ENUM.isNotEmpty],
  listA: [OPERAND_ENUM.is, OPERAND_ENUM.isNot, OPERAND_ENUM.isAnyOf,
    OPERAND_ENUM.isNoneOf, OPERAND_ENUM.isEmpty, OPERAND_ENUM.isNotEmpty],
  asyncList: [OPERAND_ENUM.is, OPERAND_ENUM.isNot, OPERAND_ENUM.isAnyOf,
    OPERAND_ENUM.isNoneOf, OPERAND_ENUM.isEmpty, OPERAND_ENUM.isNotEmpty],
  listB: [OPERAND_ENUM.isAnyOf,
    OPERAND_ENUM.isNoneOf, OPERAND_ENUM.isEmpty, OPERAND_ENUM.isNotEmpty],
  listC: [OPERAND_ENUM.is, OPERAND_ENUM.isEmpty, OPERAND_ENUM.isNotEmpty],
  numberA: [OPERAND_ENUM['='], OPERAND_ENUM['!='],
    OPERAND_ENUM['>'], OPERAND_ENUM['<'], OPERAND_ENUM.isBetween,
    OPERAND_ENUM.contains, OPERAND_ENUM.doesNotContains, OPERAND_ENUM.isEmpty,
    OPERAND_ENUM.isNotEmpty],
  numberB: [OPERAND_ENUM['='], OPERAND_ENUM['!='],
    OPERAND_ENUM['>'], OPERAND_ENUM['<'], OPERAND_ENUM.isBetween,
    OPERAND_ENUM.isEmpty, OPERAND_ENUM.isNotEmpty],
  dateA: [OPERAND_ENUM.is, OPERAND_ENUM.isWithin,
    OPERAND_ENUM.isBefore, OPERAND_ENUM.isAfter, OPERAND_ENUM.isOnOrBefore,
    OPERAND_ENUM.isOnOrAfter, OPERAND_ENUM.isEmpty, OPERAND_ENUM.isNotEmpty],
  dateB: [OPERAND_ENUM.is, OPERAND_ENUM.isWithin,
    OPERAND_ENUM.isBefore, OPERAND_ENUM.isAfter, OPERAND_ENUM.isOnOrBefore,
    OPERAND_ENUM.isOnOrAfter, OPERAND_ENUM.isEmpty, OPERAND_ENUM.isNotEmpty],
  currency: [
    OPERAND_ENUM.isEmpty, OPERAND_ENUM.isNotEmpty, OPERAND_ENUM['='],
    OPERAND_ENUM['!='], OPERAND_ENUM['<'], OPERAND_ENUM['>'],
  ],
  boolean: [OPERAND_ENUM.is],
  timeRange: [OPERAND_ENUM.isBetween, OPERAND_ENUM['is Before'], OPERAND_ENUM['is After']],
};

export const advancedFilterCommonFilter = {
  [OPERAND_ENUM.isEmpty]: {
    HTMLElement: 'empty',
    props: {},
  },
  [OPERAND_ENUM.isNotEmpty]: {
    HTMLElement: 'empty',
    props: {},
  },
};

export const DATE_FILTER_RANGE_MAP = {
  Yesterday: 'Yesterday', //
  Today: 'Today', //
  Tomorrow: 'Tomorrow', //
  'The Last Week': 'The Last Week', //
  'This Week': 'This Week', //
  'This Year': 'This Year', //
  'The Last Month': 'The Last Month', //
  'This Month': 'This Month', //
  'Custom Date Range': 'Custom Date Range',
  'Custom Date': 'Custom Date',
  'One Week Ago': 'One Week Ago', //
  'One Month Ago': 'One Month Ago', //
  'One Year Ago': 'One Year Ago', //
  'One Week From Now': 'One Week From Now', //
  'One Month From Now': 'One Month From Now', //
  'One Year From Now': 'One Year From Now', //
  'The Last Number Of Days': 'The Last Number Of Days', //
  'The Next Number Of Days': 'The Next Number Of Days', //
  'The Next Month': 'The Next Month', //
  'The Next Year': 'The Next Year', //
  'The Last Year': 'The Last Year', //
  'The Next Week': 'The Next Week', //
};

export const DATE_PICKER_INITIAL_STATE = {
  value: '',
  option: DATE_FILTER_RANGE_MAP.Today,
};

const MULTI_SELECT_PROPS = {
  isMulti: true,
  customStyle: {
    control: (base) => ({
      ...base,
      height: 29,
      minHeight: 29,
    }),
  },
};

export const AdvancedFilterPropMapping = {
  textA: {
    elementMap: {
      ...advancedFilterCommonFilter,
      [OPERAND_ENUM.is]: {
        HTMLElement: 'select',
        props: {},
      },
      [OPERAND_ENUM.isNot]: {
        HTMLElement: 'select',
        props: {},
      },
      [OPERAND_ENUM.isAnyOf]: {
        HTMLElement: 'select',
        props: MULTI_SELECT_PROPS,
      },
      [OPERAND_ENUM.isNoneOf]: {
        HTMLElement: 'select',
        props: MULTI_SELECT_PROPS,
      },
    },
  },
  textB: {
    elementMap: {
      ...advancedFilterCommonFilter,
      [OPERAND_ENUM.is]: {
        HTMLElement: 'select',
        props: {},
      },
      [OPERAND_ENUM.isNot]: {
        HTMLElement: 'select',
        props: {},
      },
      [OPERAND_ENUM.contains]: {
        HTMLElement: 'input',
        props: { type: 'text' },
      },
      [OPERAND_ENUM.doesNotContains]: {
        HTMLElement: 'input',
        props: { type: 'text' },
      },
    },
  },
  textC: {
    elementMap: {
      ...advancedFilterCommonFilter,
      [OPERAND_ENUM.is]: {
        HTMLElement: 'input',
        props: { isExact: true, type: 'text' },
      },
      [OPERAND_ENUM.isNot]: {
        HTMLElement: 'input',
        props: { isExact: true, type: 'text' },
      },
      [OPERAND_ENUM.contains]: {
        HTMLElement: 'input',
        props: { type: 'text' },
      },
      [OPERAND_ENUM.doesNotContains]: {
        HTMLElement: 'input',
        props: { type: 'text' },
      },
    },
  },
  listA: {
    elementMap: {
      ...advancedFilterCommonFilter,
      [OPERAND_ENUM.is]: {
        HTMLElement: 'select',
        props: {},
      },
      [OPERAND_ENUM.isNot]: {
        HTMLElement: 'select',
        props: {},
      },
      [OPERAND_ENUM.isAnyOf]: {
        HTMLElement: 'select',
        props: MULTI_SELECT_PROPS,
      },
      [OPERAND_ENUM.isNoneOf]: {
        HTMLElement: 'select',
        props: MULTI_SELECT_PROPS,
      },
    },
  },
  asyncList: {
    elementMap: {
      ...advancedFilterCommonFilter,
      [OPERAND_ENUM.is]: {
        HTMLElement: 'selectV2',
        props: {},
      },
      [OPERAND_ENUM.isNot]: {
        HTMLElement: 'selectV2',
        props: {},
      },
      [OPERAND_ENUM.isAnyOf]: {
        HTMLElement: 'selectV2',
        props: MULTI_SELECT_PROPS,
      },
      [OPERAND_ENUM.isNoneOf]: {
        HTMLElement: 'selectV2',
        props: MULTI_SELECT_PROPS,
      },
    },
  },
  listB: {
    elementMap: {
      ...advancedFilterCommonFilter,
      [OPERAND_ENUM.isAnyOf]: {
        HTMLElement: 'select',
        props: MULTI_SELECT_PROPS,
      },
      [OPERAND_ENUM.isNoneOf]: {
        HTMLElement: 'select',
        props: MULTI_SELECT_PROPS,
      },
    },
  },
  listC: {
    elementMap: {
      ...advancedFilterCommonFilter,
      [OPERAND_ENUM.is]: {
        HTMLElement: 'select',
        props: {},
      },
    },
  },
  numberB: {
    elementMap: {
      ...advancedFilterCommonFilter,
      [OPERAND_ENUM['=']]: {
        HTMLElement: 'input',
        props: { type: 'number' },
      },
      [OPERAND_ENUM['!=']]: {
        HTMLElement: 'input',
        props: { type: 'number' },
      },
      [OPERAND_ENUM['<']]: {
        HTMLElement: 'input',
        props: { type: 'number' },
      },
      [OPERAND_ENUM['>']]: {
        HTMLElement: 'input',
        props: { type: 'number' },
      },
      [OPERAND_ENUM.isBetween]: {
        HTMLElement: 'inputRange',
        props: { type: 'number', validation: ({ start = 0, end = 0 }) => start <= end },
      },
    },
  },
  timeRange: {
    elementMap: {
      [OPERAND_ENUM.isBetween]: {
        HTMLElement: 'timeRange',
        props: { validation: ({ start = 0, end = 0 }) => start < end },
      },
      [OPERAND_ENUM['is Before']]: {
        HTMLElement: 'timeRange',
        props: { isSingleSelect: true },
      },
      [OPERAND_ENUM['is After']]: {
        HTMLElement: 'timeRange',
        props: { isSingleSelect: true },
      },
    },
  },
  dateA: {
    elementMap: {
      ...advancedFilterCommonFilter,
      [OPERAND_ENUM.is]: {
        HTMLElement: 'date',
        props: { minDate: Date.now(), defaultValue: DATE_PICKER_INITIAL_STATE },
      },
      [OPERAND_ENUM.isWithin]: {
        HTMLElement: 'date',
        props: { minDate: Date.now(), defaultValue: DATE_PICKER_INITIAL_STATE },
      },
      [OPERAND_ENUM.isBefore]: {
        HTMLElement: 'date',
        props: { minDate: Date.now(), defaultValue: DATE_PICKER_INITIAL_STATE },
      },
      [OPERAND_ENUM.isAfter]: {
        HTMLElement: 'date',
        props: { minDate: Date.now(), defaultValue: DATE_PICKER_INITIAL_STATE },
      },
      [OPERAND_ENUM.isOnOrBefore]: {
        HTMLElement: 'date',
        props: { minDate: Date.now(), defaultValue: DATE_PICKER_INITIAL_STATE },
      },
      [OPERAND_ENUM.isOnOrAfter]: {
        HTMLElement: 'date',
        props: { minDate: Date.now(), defaultValue: DATE_PICKER_INITIAL_STATE },
      },
    },
  },
  currency: {
    elementMap: {
      ...advancedFilterCommonFilter,
      [OPERAND_ENUM['=']]: {
        HTMLElement: 'currency',
        props: {},
      },
      [OPERAND_ENUM['!=']]: {
        HTMLElement: 'currency',
        props: {},
      },
      [OPERAND_ENUM['<']]: {
        HTMLElement: 'currency',
        props: {},
      },
      [OPERAND_ENUM['>']]: {
        HTMLElement: 'currency',
        props: {},
      },
    },
  },
  boolean: {
    elementMap: {
      [OPERAND_ENUM.is]: {
        HTMLElement: 'select',
        props: {
          options: [{ label: 'True', value: 'true' }, { label: 'False', value: 'false' }],
        },
      },
    },
  },
};

AdvancedFilterPropMapping.numberA = {
  ...AdvancedFilterPropMapping.numberB,
  elementMap: {
    ...AdvancedFilterPropMapping.numberB.elementMap,
    [OPERAND_ENUM.contains]: {
      HTMLElement: 'input',
      props: { type: 'number' },
    },
    [OPERAND_ENUM.doesNotContains]: {
      HTMLElement: 'input',
      props: { type: 'number' },
    },
  },
};
AdvancedFilterPropMapping.dateB = AdvancedFilterPropMapping.dateA;

export const CUSTOM_FIELD_HTML_CROSS_MAP = {
  [CUSTOM_FIELD_TYPE_ENUM.Phone]: ADVANCE_FILTER_HTML_ENUM.numberB,
  [CUSTOM_FIELD_TYPE_ENUM.Number]: ADVANCE_FILTER_HTML_ENUM.numberB,
  [CUSTOM_FIELD_TYPE_ENUM.Text]: ADVANCE_FILTER_HTML_ENUM.textC,
  [CUSTOM_FIELD_TYPE_ENUM.List]: ADVANCE_FILTER_HTML_ENUM.listA,
  [CUSTOM_FIELD_TYPE_ENUM.MultiList]: ADVANCE_FILTER_HTML_ENUM.listA,
  [CUSTOM_FIELD_TYPE_ENUM.Date]: ADVANCE_FILTER_HTML_ENUM.dateA,
  [CUSTOM_FIELD_TYPE_ENUM.Percentage]: ADVANCE_FILTER_HTML_ENUM.numberB,
  [CUSTOM_FIELD_TYPE_ENUM.Amount]: ADVANCE_FILTER_HTML_ENUM.currency,
  [CUSTOM_FIELD_TYPE_ENUM.Boolean]: ADVANCE_FILTER_HTML_ENUM.boolean,
  [CUSTOM_FIELD_TYPE_ENUM.TimeRange]: ADVANCE_FILTER_HTML_ENUM.timeRange,
};

const DATE_OPERAND = {
  [OPERAND_ENUM.is]: [DATE_FILTER_RANGE_MAP.Yesterday, DATE_FILTER_RANGE_MAP.Today, DATE_FILTER_RANGE_MAP['One Week Ago'],
    DATE_FILTER_RANGE_MAP['One Month Ago'], DATE_FILTER_RANGE_MAP['One Year Ago'], DATE_FILTER_RANGE_MAP['Custom Date'],
  ],
  [OPERAND_ENUM.isWithin]: [DATE_FILTER_RANGE_MAP['The Last Number Of Days'], DATE_FILTER_RANGE_MAP['The Next Number Of Days'],
    DATE_FILTER_RANGE_MAP['The Last Week'], DATE_FILTER_RANGE_MAP['This Week'], DATE_FILTER_RANGE_MAP['The Last Month'], DATE_FILTER_RANGE_MAP['This Month'],
    DATE_FILTER_RANGE_MAP['The Last Year'], DATE_FILTER_RANGE_MAP['This Year'], DATE_FILTER_RANGE_MAP['Custom Date Range'],
  ],
  [OPERAND_ENUM.isBefore]: [DATE_FILTER_RANGE_MAP.Yesterday, DATE_FILTER_RANGE_MAP.Today,
    DATE_FILTER_RANGE_MAP['The Last Week'], DATE_FILTER_RANGE_MAP['The Last Month'], DATE_FILTER_RANGE_MAP['The Last Year'],
    DATE_FILTER_RANGE_MAP['Custom Date'],
  ],
};

DATE_OPERAND[OPERAND_ENUM.isNot] = DATE_OPERAND[OPERAND_ENUM.is];
DATE_OPERAND[OPERAND_ENUM.isOnOrBefore] = DATE_OPERAND[OPERAND_ENUM.isBefore];
DATE_OPERAND[OPERAND_ENUM.isOnOrAfter] = DATE_OPERAND[OPERAND_ENUM.isBefore];
DATE_OPERAND[OPERAND_ENUM.isAfter] = DATE_OPERAND[OPERAND_ENUM.isBefore];

export const DATE_OPERAND_MAPPING = {
  [ADVANCE_FILTER_HTML_ENUM.dateA]: DATE_OPERAND,
  [ADVANCE_FILTER_HTML_ENUM.dateB]: DATE_OPERAND,
};

export const DATE_FILTER_RANGE_HTML_TYPE_MAP = {
  Yesterday: 'empty', //
  Today: 'empty', //
  Tomorrow: 'empty', //
  'The Last Week': 'empty', //
  'This Week': 'empty', //
  'This Year': 'empty', //
  'The Last Month': 'empty', //
  'This Month': 'empty', //
  'Custom Date Range': 'customDate',
  'Custom Date': 'customDate',
  'One Week Ago': 'empty', //
  'One Month Ago': 'empty', //
  'One Year Ago': 'empty', //
  'One Week From Now': 'empty', //
  'One Month From Now': 'empty', //
  'One Year From Now': 'empty', //
  'The Last Number Of Days': 'input', //
  'The Next Number Of Days': 'input', //
  'The Next Month': 'empty', //
  'The Next Year': 'empty', //
  'The Last Year': 'empty', //
  'The Next Week': 'empty', //
};

export const CONIDITION_SITUATION = [
  { label: 'And', value: 'And' }, { label: 'Or', value: 'Or' },
];
export const CALL_TYPE_OPTIONS = [
  { label: 'Outbound Call', value: 0 },
  { label: 'Inbound Call', value: 1 },
  { label: 'Missed Call', value: 2 },
];

export const BOOLEAN_OPTIONS = [{ label: 'True', value: true }, { label: 'False', value: false }];

export const TIME_RANGE_OPTIONS = [{
  value: 2400,
  label: '12:00 AM',
}, {
  value: 100,
  label: '1:00 AM',
}, {
  value: 200,
  label: '2:00 AM',
}, {
  value: 300,
  label: '3:00 AM',
}, {
  value: 400,
  label: '4:00 AM',
}, {
  value: 500,
  label: '5:00 AM',
}, {
  value: 600,
  label: '6:00 AM',
}, {
  value: 700,
  label: '7:00 AM',
}, {
  value: 800,
  label: '8:00 AM',
}, {
  value: 900,
  label: '9:00 AM',
}, {
  value: 1000,
  label: '10:00 AM',
}, {
  value: 1100,
  label: '11:00 AM',
}, {
  value: 1200,
  label: '12:00 PM',
}, {
  value: 1300,
  label: '1:00 PM',
}, {
  value: 1400,
  label: '2:00 PM',
}, {
  value: 1500,
  label: '3:00 PM',
}, {
  value: 1600,
  label: '4:00 PM',
}, {
  value: 1700,
  label: '5:00 PM',
}, {
  value: 1800,
  label: '6:00 PM',
}, {
  value: 1900,
  label: '7:00 PM',
}, {
  value: 2000,
  label: '8:00 PM',
}, {
  value: 2100,
  label: '9:00 PM',
}, {
  value: 2200,
  label: '10:00 PM',
}, {
  value: 2300,
  label: '11:00 PM',
}];

export const INFINITE_DATE = 10000002599999;
