import { isValid } from 'date-fns';
import { isArray, isNumber, isString } from './dataType.util';

const CUSTOM_FIELD_TYPE_ENUM = {
  Phone: 'Phone',
  Text: 'Text',
  Number: 'Number',
  List: 'List',
  Date: 'Date',
  MultiList: 'MultiList',
  LongText: 'LongText',
  Email: 'Email',
  Boolean: 'Boolean',
  URL: 'URL',
  Percentage: 'Percentage',
  Amount: 'Amount',
};

function isValidURL(string) {
  // eslint-disable-next-line
  const res = string.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
  return (res !== null);
}

function isEmail(email) {
  // eslint-disable-next-line no-useless-escape
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

function isPhoneNumber(number) {
  const newNumber = number.replace(/\D/g, '');
  // eslint-disable-next-line no-useless-escape
  const phoneno = /^([0|\+[0-9]{1,16})$/;
  if (newNumber.match(phoneno)) {
    return true;
  }
  return false;
}

export function isValidCreditValue(number) {
  const newNumber = Number(number);
  if (isNumber(newNumber) && newNumber > 0) {
    return true;
  }
  return false;
}

function isValidLong(text, reqLength = 3) {
  return text.length < reqLength;
}

export function isShortText(text) {
  return isString(text) && text.length <= 64;
}

/**
 * Function isValidObject - check object for falseable values eg. 0, null, undefined, ''
 * @param {object} dataObject
 */
function isValidObject(dataObject) {
  let isValidValueObject = true;
  const dataKeys = Object.keys(dataObject);
  for (let index = 0; index < dataKeys.length; index += 1) {
    if (!dataObject[dataKeys[index]]) {
      isValidValueObject = false;
      break;
    }
  }
  return isValidValueObject;
}

export const isValidDomian = (domain) => {
  // eslint-disable-next-line
  const domainRegex = new RegExp(/^(?!(https:\/\/|http:\/\/|www\.|mailto:|smtp:|ftp:\/\/|ftps:\/\/))(((([a-zA-Z0-9])|([a-zA-Z0-9][a-zA-Z0-9\-]{0,86}[a-zA-Z0-9]))\.(([a-zA-Z0-9])|([a-zA-Z0-9][a-zA-Z0-9\-]{0,73}[a-zA-Z0-9]))\.(([a-zA-Z0-9]{2,12}\.[a-zA-Z0-9]{2,12})|([a-zA-Z0-9]{2,25})))|((([a-zA-Z0-9])|([a-zA-Z0-9][a-zA-Z0-9\-]{0,162}[a-zA-Z0-9]))\.(([a-zA-Z0-9]{2,12}\.[a-zA-Z0-9]{2,12})|([a-zA-Z0-9]{2,25}))))$/g);
  return domainRegex.test(domain);
};

export const isValidWebsiteURL = (url) => {
  // eslint-disable-next-line no-useless-escape
  const websiteRegex = new RegExp(/[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi);
  return websiteRegex.test(url);
};

export const validateTags = (tags) => {
  if (!isArray(tags)) return [];
  return tags.filter((d) => isString(d));
};

export const extractHostNameFromURL = (link) => {
  const regex = /^(?:https?:\/\/)?(?:[^@/\n]+@)?(?:www\.)?([^:/?\n]+)/;
  const arrayOfResult = regex.exec(link);
  let result = null;
  for (let index = 0; index < arrayOfResult.length; index += 1) {
    if (isValidDomian(arrayOfResult[index])) {
      result = arrayOfResult[index];
      break;
    }
  }
  return result;
};

export const checkAndGenerateDomainFromURL = (url) => {
  let result = false;
  if (url) {
    if (isValidDomian(url)) result = url;
    if (extractHostNameFromURL(url)) result = extractHostNameFromURL(url);
  }
  return result;
};

// Imp: startWith is Case Sensitive
export const isTokenIncludeErr = (token) => (token || '').startsWith('ERR-');
export const isValidNylasToken = (token) => !!(token && isString(token) && !isTokenIncludeErr(token));

export const isValidDate = (value) => isValid(value);

export const isValidPercentage = (value) => value >= 0 && value <= 100;

export const IMPORT_CSV_COLUM_VALIDATOR_MAP = {
  [CUSTOM_FIELD_TYPE_ENUM.Email.toLowerCase()]: (val) => val && isEmail(val?.trim()),
  [CUSTOM_FIELD_TYPE_ENUM.Phone.toLowerCase()]: (val) => isString(val) && isPhoneNumber(val),
  [CUSTOM_FIELD_TYPE_ENUM.Text.toLowerCase()]: isShortText,
  [CUSTOM_FIELD_TYPE_ENUM.Percentage.toLowerCase()]: (val) => val && isValidPercentage(Number(val)),
  [CUSTOM_FIELD_TYPE_ENUM.Amount.toLowerCase()]: (val) => val && isNumber(Number(val)),
  [CUSTOM_FIELD_TYPE_ENUM.Number.toLowerCase()]: (val) => val && isNumber(Number(val)),
  [CUSTOM_FIELD_TYPE_ENUM.URL.toLowerCase()]: (val) => val && isValidURL(val?.trim()),
  firstName: (name) => !!name?.trim(),
  accountDomain: (val) => val && checkAndGenerateDomainFromURL(val?.trim()),
  default: () => true,
};

export {
  isEmail,
  isPhoneNumber,
  isValidLong,
  isValidObject,
};
