import API from '../libs/amplify.lib';
import { API_NAME } from '../constants';
import { generateProjectionExpressionV2, generateQueryParamsFromFilterV2WithTile } from '../Utils/query.v2.util';
import { OPERAND_ENUM } from '../constants/filter.constant';
import { parseResponse, responseCallback } from '../libs/response.lib';
import { CLIENT_TIMEZONE } from '../Utils/date.util';

export const DEFAULT_PROJECTIONS = {
  prospect: {
    firstName: 1, lastName: 1, prospectEmail: 1, isBlacklisted: 1, lastContacted: 1, position: 1, organisation: 1, assignedWorkflows: 1,
  },
  clientAccount: {
    name: 1, accountDomain: 1, lastContacted: 1,
  },
  opportunity: {
    name: 1, lastContacted: 1,
  },
};

export const FIELD_UPDATE_TYPE = {
  basicField: 'basicField',
  customField: 'customField',
  tagUpdate: 'tagUpdate',
};

Object.freeze(FIELD_UPDATE_TYPE);

/**
 *
 * @param {*} field - stage, taskName
 * @param {*} query - { conditions: [], operator: 'And/Or' }
 * @returns
 */
export const queryTilesData = async ({
  entity, accountId, query, field, operation = 'tile',
}) => API.post(API_NAME, `/${entity}?operation=${operation}`, {
  body: {
    accountId, field, query,
  },
})
  .then(parseResponse);

/**
 *
 * @param {*} search
 * @param {*} query - eg. { conditions: [], operator: 'And/Or' }
 * @param {*} projection - eg. { firstName: 1 }
 * @returns
 */
export const queryList = async ({
  accountId, query, search, sort, requestId, projection, skip, limit, entity, noDefault = false, sendEntityDetails = true,
  showWorkflowForAssignment = false,
}) => API.post(API_NAME, `/${entity}?operation=list`, {
  body: {
    accountId,
    query,
    search: (search || ''),
    sort,
    projection: {
      ...projection, ...(noDefault ? {} : (DEFAULT_PROJECTIONS[entity] || {})),
    },
    skip,
    limit,
    requestId,
    sendEntityDetails,
    showWorkflowForAssignment,
  },
})
  .then(parseResponse);

export const getEntityDetails = async ({ id, entity }) => API.get(API_NAME, `/${entity}?operation=detail&_id=${encodeURIComponent(id)}`)
  .then((resp) => {
    const result = JSON.parse(responseCallback(resp).body).data;
    if (result.statusCode === 404) {
      throw new Error(result.message);
    }
    return result;
  });

export const gueryDistinctFields = async ({
  entity, accountId, field, search,
}) => API.post(API_NAME, `/${entity}?operation=getDistinctValues`, {
  body: { accountId, field, search },
})
  .then(parseResponse);

export const noteApi = async ({
  entity, data, operation,
}) => API.post(API_NAME, `/${entity}?operation=${operation}`, {
  body: data,
})
  .then(parseResponse);

export const updateNoteApi = async ({
  entity, data, operation,
}) => API.put(API_NAME, `/${entity}?operation=${operation}`, {
  body: data,
})
  .then(parseResponse);

export const createEntity = async ({
  entity, data,
}) => API.post(API_NAME, `/${entity}`, {
  body: data,
})
  .then(parseResponse);

export const updateEntity = async ({
  entity, data, _id,
}) => API.put(API_NAME, `/${entity}?operation=single`, {
  body: {
    data, _id,
  },
})
  .then(parseResponse);

export const multipleUpdateEntities = async ({
  entity, data, ids, query, accountId, fieldToUpdate, search, tile, workflowId,
}) => API.put(API_NAME, `/${entity}?operation=multiple`, {
  body: {
    data, ids, query, accountId, fieldToUpdate, search, tile, workflowId,
  },
})
  .then(parseResponse);

export const deleteEntity = async ({
  entity, _id,
}) => API.del(API_NAME, `/${entity}?operation=single`, {
  body: { _id },
})
  .then(parseResponse);

export const multipleDeleteEntities = async ({
  entity, ids, query, accountId, search,
}) => API.del(API_NAME, `/${entity}?operation=multiple`, {
  body: {
    ids, query, accountId, search,
  },
})
  .then(parseResponse);

export const exportDataV2 = async ({
  accountId, requestId, entity, columnConfig, filter, search, tile, skip = 0,
}) => {
  const params = generateQueryParamsFromFilterV2WithTile(filter, search, tile);
  const projection = generateProjectionExpressionV2(columnConfig);
  return API.post(API_NAME, `/${entity}?operation=export`, {
    body: {
      accountId,
      requestId,
      skip,
      projection,
      ...params,
    },
  })
    .then(parseResponse);
};

export const exportTaskDataV2 = async ({
  accountId, requestId, filter, search, skip = 0,
}) => {
  const params = generateQueryParamsFromFilterV2WithTile(filter, search);
  params.query.groupBy = filter.groupBy;
  params.query.dateRange = filter.dateRange;
  filter.tile !== 'All' && (params.query.tile = filter.tile);
  return API.post(API_NAME, '/task?operation=export', {
    body: {
      accountId,
      requestId,
      skip,
      ...params,
    },
  })
    .then(parseResponse);
};

export const exportLoggedCallDataV2 = async ({
  accountId, requestId, filter, search, skip = 0,
}) => {
  const params = generateQueryParamsFromFilterV2WithTile(filter, search);
  params.query.callDispositionTile = filter.tile;
  params.query.dateRange = filter.dateRange;
  return API.post(API_NAME, '/task?operation=exportCalls', {
    body: {
      accountId,
      requestId,
      skip,
      ...params,
    },
  })
    .then(parseResponse);
};

export const queryWorkflowProspectTile = async ({
  accountId, workflowId, filter, search, requestId,
}) => {
  const params = generateQueryParamsFromFilterV2WithTile(filter, search);
  return API.post(API_NAME, '/workflow?operation=wfTaskTile', {
    body: {
      accountId,
      workflowId,
      clientTimeZone: CLIENT_TIMEZONE,
      requestId,
      ...params,
    },
  })
    .then(parseResponse);
};

export const queryWorkflowProspectList = async ({
  accountId, workflowId, tile, query, search, sort, requestId, projection, skip, limit,
}) => API.post(API_NAME, '/workflow?operation=wfTaskList', {
  body: {
    clientTimeZone: CLIENT_TIMEZONE,
    workflowId,
    accountId,
    skip,
    limit,
    requestId,
    projection,
    tile,
    query,
    search,
    sort,
  },
})
  .then(parseResponse);

export const getProspectOpportunity = async ({ prospectEmail, accountId, requestId }) => {
  const query = {
    conditions: {
      filter: 'prospects',
      operator: OPERAND_ENUM.isAnyOf,
      value: [prospectEmail],
    },
    operator: 'And',
  };
  return queryList({
    accountId,
    query,
    requestId,
    projection: {
      _id: 1, name: 1, ownerId: 1, amount: 1, stage: 1,
    },
    skip: 0,
    entity: 'opportunity',
  });
};

export const getAccountProspects = async ({ _id, accountId, requestId }) => {
  const query = {
    conditions: {
      filter: 'prospectAccountId',
      operator: OPERAND_ENUM.is,
      value: _id,
    },
    operator: 'And',
  };
  return queryList({
    accountId,
    query,
    requestId,
    projection: DEFAULT_PROJECTIONS.prospect,
    skip: 0,
    entity: 'prospect',
  });
};

export const batchGetEntities = async ({
  accountId, _ids, requestId, projection, entity,
}) => API.post(API_NAME, `/${entity}?operation=batchGet`, {
  body: {
    accountId,
    _ids,
    projection,
    requestId,
  },
})
  .then(parseResponse);

export const getProspectData = async ({ prospectEmail, accountId }) => {
  try {
    const resp = await getEntityDetails({
      id: `${accountId}:${prospectEmail}`, entity: 'prospect',
    });
    return resp;
  } catch (error) {
    return { prospectEmail, accountId };
  }
};

/**
 *
 * @param {*} search
 * @param {*} query - eg. { conditions: [], operator: 'And/Or' }
 * @param {*} projection - eg. { firstName: 1 }
 * @returns
 */
export const queryEmailList = async ({
  accountId, prospectEmail, search, sort, requestId, skip, limit, sendEntityDetails = false,
}) => API.post(API_NAME, '/email?operation=listProspectEmails', {
  body: {
    accountId,
    prospectEmail,
    search: (search || ''),
    sort,
    skip,
    limit,
    requestId,
    sendEntityDetails,
  },
})
  .then(parseResponse);

/**
 *
 * @param {*} search
 * @param {*} query - eg. { conditions: [], operator: 'And/Or' }
 * @param {*} projection - eg. { firstName: 1 }
 * @returns
 */
export const queryCallList = async ({
  accountId, query, search, sort, requestId, skip, limit, sendEntityDetails = true,
}) => API.post(API_NAME, '/task?operation=listCalls', {
  body: {
    accountId,
    query,
    search: (search || ''),
    sort,
    skip,
    limit,
    requestId,
    sendEntityDetails,
  },
})
  .then(parseResponse);

/**
 *
 * @param {*} search
 * @param {*} query - eg. { conditions: [], operator: 'And/Or' }
 * @param {*} projection - eg. { firstName: 1 }
 * @returns
 */
export const queryAccountTaskList = async ({
  _id,
}) => API.post(API_NAME, '/task?operation=listAccountTasks', {
  body: {
    _id,
  },
})
  .then(parseResponse);

export const getFailedEmailCount = async (accountId) => API.post(API_NAME, '/email?operation=failedEmailCount', { body: { accountId } }).then(parseResponse);
